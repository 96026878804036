export const localStorageKeys = {
    accessToken:'accessToken',
    is_pre_appointment_filled:'is_pre_appointment_filled',
    user:'user'
}

export const useLocalStorage = () =>{
    const setLocalStorageItem = (itemKey, itemValue)=>{
        localStorage.setItem(itemKey, itemValue);
    }
    const getLocalStorageItem = (itemKey) => {
        return localStorage.getItem(itemKey)
    }
    const removeLocalStorageItem = (itemKey) =>{
        localStorage.removeItem(itemKey)
    }

    return {
        setLocalStorageItem,
        getLocalStorageItem,
        removeLocalStorageItem
    }
}