import React, { useState, useEffect } from "react";
import btnBack from "../../assets/btnBack.svg";
import Banner_Img from "../../assets/Banner_Img.svg";

import styles from "./index.module.scss";
import PageLayout from "../../Layout/PageLayout";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { scaleByRatio } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { routeUrls } from "../../utils/constants";

const SignupPageLayout = (props) => {
  const nav = useNavigate();
  const backClicked = () => {
    nav(routeUrls.landingPage);
  };
  return (
    <PageLayout>
      <Box
        component="main"
        className={"mainBody"}
        sx={{
          marginTop: { xs: `${scaleByRatio(104)}px`, xl: `104px` },
          marginBottom: { xs: `${scaleByRatio(160)}px`, xl: `160px` },
        }}
      >
        <Grid
          container
          fullWidth
          direction="row"
          className={styles.banner}
          sx={{
            display: { xs: `flex`, sm: "flex" },
            minHeight: { xs: `${scaleByRatio(720)}px`, xl: `720px` },
            borderRadius: "0px 0px 40px 40px",
            //backgroundSize: `${scaleByRatio(1490)}px ${scaleByRatio(544)}px`
          }}
        >
          <Grid
            container
            fullWidth
            direction="row"
            className={styles.bannerContent}
            sx={{
              marginLeft: {
                xs: "10px",
                sm: `${scaleByRatio(151)}px`,
                xl: `151px`,
              },
              marginRight: {
                xs: "10px",
                sm: `${scaleByRatio(151)}px`,
                xl: `151px`,
              },
              marginTop: {
                xs: "10px",
                sm: `${scaleByRatio(41)}px`,
                xl: `41px`,
              },
            }}
          >
            <Grid
              item
              sm={8}
              sx={{
                display: { xs: `none`, sm: `block` },
              }}
            >
              <Grid item xs={12} justifyContent={"left"} alignItems={"start"}>
                <Typography
                  sx={{
                    flexGrow: 1,
                    height: { xs: `${scaleByRatio(48)}px`, xl: `48px` },
                  }}
                >
                  <Box
                    component="img"
                    src={btnBack}
                    alt="back"
                    className={styles.appIcon}
                    sx={{
                      height: { xs: `${scaleByRatio(48)}px`, xl: `48px` },
                      width: { xs: `${scaleByRatio(48)}px`, xl: `48px` },
                      cursor: "pointer",
                    }}
                    onClick={props.backClicked || backClicked}
                  />
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"left"}
                alignItems={"end"}
                sx={{
                  pt: { xs: `${scaleByRatio(45)}px`, xl: `68px` },
                }}
              >
                <Typography sx={{ flexGrow: 1 }}>
                  <Box
                    component="img"
                    src={Banner_Img}
                    alt="banner"
                    className={styles.appIcon}
                    sx={{
                      height: { xs: `${scaleByRatio(557)}px`, xl: `557px` },
                      width: { xs: `${scaleByRatio(556)}px`, xl: `556px` },
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            {props.children}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
};

export default SignupPageLayout;
