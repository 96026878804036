import { createBrowserRouter } from "react-router-dom";
import { routeUrls } from "../constants";
import LandingPage from "../../Pages/LandingPage";
import SignUp from "../../Pages/SignUp";
import SignUpComplete from "../../Pages/SignUpComplete";
import TermsAndConditions from "../../Pages/TermsAndConditions";
import PrivacyPolicy from "../../Pages/PrivacyPolicy";
import SignupSubscription from '../../Pages/SignupSubscription'

const routes = [
  {
    path: routeUrls.landingPage,
    element: <LandingPage />,
  },
  {
    path: routeUrls.signUpPage,
    element: <SignUp />,
  },
  {
    path: routeUrls.signupSubscription,
    element: <SignupSubscription />,
  },
  {
    path: routeUrls.signUpComplete,
    element: <SignUpComplete />,
  },
  {
    path: routeUrls.termsAndConditions,
    element: <TermsAndConditions />,
  },
  {
    path: routeUrls.privacyPolicy,
    element: <PrivacyPolicy />,
  },
];

const router = createBrowserRouter(routes);

export default router;
