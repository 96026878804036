import React, { useState, useEffect } from 'react'
import AppLogo from '../../assets/AppLogo.svg'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper'
import { routeUrls } from '../../utils/constants'
import {  useNavigate } from "react-router-dom";


const Header = () => {
    let nav = useNavigate()
    return ( 
        <Box>
            <AppBar className={styles.header} component="nav">
                <Toolbar sx={{height:{xs:`${scaleByRatio(100)}px`,xl:`100px`}}}>
                <Typography variant='h6' component='div' sx={{ flexGrow: 1, paddingLeft: '32px', minHeight: `${scaleByRatio(100)}px` }}>
                    <img src={AppLogo} alt='App Icon' className={styles.appIcon} 
                    sx={{
                      height:{xs:`${scaleByRatio(100)}px`, xl:`100px`},
                      width:{xs:`${scaleByRatio(209)}px`, xl:`209px`},
                      cursor:'pointer'
                    }} 
                    onClick={()=>{
                        nav(routeUrls.landingPage)
                    }}
                    />
                </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
 
export default Header;