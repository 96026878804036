import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent as PersonalizeWorkoutLogo } from "../../assets/rafiki.svg";
import styled from "@emotion/styled";
import { scaleByRatio } from "../../utils/helper";
import { ReactComponent as PathLogo } from "../../assets/path38.svg";
import Button from "../../Components/Button";

const StyledPersonalizeWorkoutLogo = styled(PersonalizeWorkoutLogo)({
  width: "100%",
});

const AboutUs = () => {
  return (
    <Grid
      p={1}
      container
      justifyContent="space-around"
      alignItems="center"
      sx={{ backgroundColor: "", marginTop: "23%" }}
    >
      <Grid
        item
        xs={12}
        md={5}
        sx={{ backgroundColor: "", width: "100%", height: "auto" }}
      >
        <StyledPersonalizeWorkoutLogo />
      </Grid>
      <Grid item xs={12} md={6} sx={{ backgroundColor: "", width: "100%" }}>
        <Typography
          sx={{ fontSize: "2rem", fontWeight: "700", color: "#FFC200" }}
        >
          About Us
        </Typography>
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "400", color: "#444444" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          quis eros posuere, fermentum odio quis, elementum odio. Duis sagittis
          viverra purus sed egestas.
        </Typography>
        <Grid sx={{ fontSize: "1rem", fontWeight: "400", color: "#444444" }}>
          <Grid item p={2}>
            <PathLogo />
            <Typography ml={2} display="inline">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              quis eros posuere, fermentum odio quis, elementum odio. Duis
              sagittis viverra purus sed egestas.
            </Typography>
          </Grid>
          <Grid item p={2}>
            <PathLogo />

            <Typography ml={2} display="inline">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              quis eros posuere, fermentum odio quis, elementum odio. Duis
              sagittis viverra purus sed egestas.
            </Typography>
          </Grid>
          <Grid item p={2}>
            <PathLogo />
            <Typography ml={2} display="inline">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
              quis eros posuere, fermentum odio quis, elementum odio. Duis
              sagittis viverra purus sed egestas.
            </Typography>
          </Grid>
          <Grid item p={2}>
            <Button label="Know More about us" variant="contained" />

            {/* <CustomButton color="red">Know More About Us</CustomButton> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
