import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./index.module.scss";

import LandingPageFooter from "../../Components/LandingPageFooter";
import LandingPageHeader from "../../Components/LandingPageHeader";
import Footer from "../../organism/footer";

const LandingPageLayout = (props) => {
  return (
    <Box xs={12}>
      <LandingPageHeader />
      <Box component="main" className={styles.main} sx={{ flexGrow: 1 }}>
        {props.children}
      </Box>
      <Footer />
    </Box>
  );
};

export default LandingPageLayout;
