import React from "react";
import MUIButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const CustomButton = styled(MUIButton)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#223C6F",
  height: "auto",
  fontWeight: "700",
  fontSize: "1.25rem",
  textTransform: "uppercase",
  backgroundColor: "#FFC200",
  padding: "0.5rem 1rem",
  borderRadius: "11rem",
  "&:hover": {
    backgroundColor: "none",
    color: "white",
  },
});

const Button = ({
  label = "",
  variant = "contained",
  onClick,
  disabled,
  loading,
  type,
  ...props
}) => {
  return (
    <CustomButton
      variant={variant}
      onClick={onClick}
      disableRipple
      disableElevation
      disabled={disabled || loading}
      type={type}
      {...props}
    >
      <span className="title-text" title={label}>
        {label}
      </span>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </CustomButton>
  );
};

export default Button;
