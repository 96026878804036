import axios from "axios"
import { API_BASE_PATH, API_PATH, PATH } from "../utils/config"
import {localStorageKeys, useLocalStorage} from '../utils/localStorageItems'

const getBaseUrl = () => {
  let baseUrl = API_BASE_PATH
  
  return baseUrl
}

const instance = axios.create({
  baseURL: getBaseUrl()
})

const notLoginCall = config => {
  return (
    (config.url !== API_PATH.POST_SIGNUP) &&
    (config.url !== API_PATH.GET_TERMS_AND_CONDITIONS) && 
    (config.url !== API_PATH.GET_PRIVACY_POLICY) && 
    (config.url !== API_PATH.POST_MESSAGE_CONTACTUS)
    // && 
    // (config.url !== API_PATH.VERIFY_OTP)
    )
}

instance.interceptors.request.use(
  request => {
    // console.log(request)
    if (!request.headers.Authorization) {
        const localStorageItem = useLocalStorage()
        const token = localStorageItem.getLocalStorageItem(localStorageKeys.accessToken)
      if (token) {
        request.headers.Authorization = `Token ${token}`
      }
    }
    return request
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  },
  { runWhen: notLoginCall }
)

// instance.interceptors.response.use(
//   response => {
//     if (
//       response?.config?.url === API_PATH.LOGIN ||
//       response?.config?.url === API_PATH.GOOGLE_LOGIN ||
//       response?.config?.url === API_PATH.FACEBOOK_LOGIN
//     ) {
//       let token = response?.data?.token
//       let user = response?.data?.user
//       localStorage.setItem("keyCloak", JSON.stringify({ token, user }))
//     }
//     // console.log(response)
//     return response
//   },
//   error => {
//     console.log(error)
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       error.response.data.detail === "Invalid token."
//     ) {
//       localStorage.removeItem("keyCloak")
//       window.location.href = "." + PATH.LOGIN
//     }
//     // error.response.status === 403 && error.response.data.detail === "CSRF Failed: CSRF token missing or incorrect."
//     return Promise.reject(error)
//   }
// )

export default instance
