import { Box, Grid, TextField, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as ContactUsImage } from "../../assets/contactus.svg";
import { useFormik, Field, FormikProvider, ErrorMessage } from "formik";
import { scaleByRatio } from "../../utils/helper";
import Button from "../../Components/Button";
import styles from "./index.module.scss";
import * as Yup from "yup";
import { useSendMsgFromContactUs } from "../../services/mutations";
import { toast } from "react-toastify";
import useScreenType from "react-screentype-hook";
import Loader from "../../molecule/Loader";

const StyledContactUsImage = styled(ContactUsImage)({
  width: "100%",
  height: "auto",
});

const ContactUs = () => {
  const screenType = useScreenType();
  const sendMessage = useSendMsgFromContactUs();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter the title."),
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email"),
      message: Yup.string().required("Please enter the message."),
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      setLoading(true);
      let data = {
        title: values.title,
        email: values.email,
        message: values.message,
      };
      sendMessage.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          console.log(response);
          toast.success("Message sent successfully");
          formik.resetForm();
        },
        onError: (response) => {
          setLoading(false);
          toast.error(
            response?.message || "Something went wrong. Please try again."
          );
        },
      });
    },
  });

  return (
    <>
      <Loader open={loading} />
      <Grid
        // mt={4}
        p={3}
        container
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column-reverse", md: "row" }}
        sx={{
          backgroundColor: "",
          // marginTop: "5rem",
        }}
      >
        <Grid
          item
          mt={5}
          md={4}
          xs={12}
          sx={
            {
              // width: "27.6875rem", height: "auto"
            }
          }
        >
          <Typography
            mb={1}
            sx={{
              fontSize: "2rem",
              fontWeight: "700",
              color: "#FFC200",
              fontFamily: "kiona",
            }}
          >
            CONTACT US
          </Typography>
          <Typography
            mb={2}
            sx={{
              fontSize: "1.25rem",
              fontWeight: "400",
              color: "#444444",
              fontFamily: "Fire Sans",
              lineHeight: "2rem",
            }}
          >
            Have questions? If our FAQ doesn't have the info you need, send us a
            message and one of our staff will get back to you as soon as we can!{" "}
          </Typography>
          {/* </Grid> */}
          <FormikProvider value={formik}>
            <form>
              <Grid item pb={6}>
                <Grid item pt={1}>
                  <Typography
                    pb={1}
                    className={styles.label}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    TITLE
                  </Typography>
                  <Field
                    type="text"
                    name="title"
                    placeholder="Please enter a title"
                    handleChange={formik.handleChange}
                    value={formik.values.title}
                    className={styles.inputBox}
                    multiline={false}
                    style={{}}
                  />
                  <span className={styles.txtError}>
                    <ErrorMessage name={"title"} />
                  </span>
                </Grid>
                <Grid item pt={1}>
                  <Typography
                    pb={1}
                    className={styles.label}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    EMAIL
                  </Typography>
                  <Field
                    type="text"
                    name="email"
                    placeholder="Please enter your email"
                    handleChange={formik.handleChange}
                    value={formik.values.email}
                    className={styles.inputBox}
                    multiline={false}
                    style={{}}
                  />
                  <span className={styles.txtError}>
                    <ErrorMessage name={"email"} />
                  </span>
                </Grid>
                <Grid item pb={3} pt={1}>
                  <Typography
                    pb={1}
                    className={styles.label}
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    MESSAGE TEXT
                  </Typography>
                  <Field
                    type="text"
                    name="message"
                    placeholder="Please write down your message here."
                    handleChange={formik.handleChange}
                    value={formik.values.message}
                    className={styles.inputBox}
                    multiline={true}
                    style={{
                      minHeight: `${scaleByRatio(150)}px`,
                      //paddingBottom: "86px",
                    }}
                    component="textarea"
                  />
                  <span className={styles.txtError}>
                    <ErrorMessage name={"message"} />
                  </span>
                </Grid>
                <Button
                  label="SEND"
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ width: "100%" }}
                  onClick={formik.handleSubmit}
                />
              </Grid>
            </form>
          </FormikProvider>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={
            {
              // marginTop: { xs: "80rem", md: "0rem" }
            }
          }
        >
          <StyledContactUsImage />
        </Grid>
      </Grid>
    </>
  );
};

export default ContactUs;
