import React, {useEffect, useState} from 'react'
import { Button,Typography, FormControl } from '@mui/material'
import styles from './input.module.scss'
import {scaleByRatio} from '../../utils/helper'

export const ButtonVariant = {
    text:'text',
    contained: 'contained',
    outlined: 'outlined'
}

export const ButtonType = {
    primary:'primary',
    secondary:'secondary',
    custom:'custom',
    yes:'yes',
    no:'no'
}

export const CustomButton = ({
    variant,
    text,
    onClick,
    type = ButtonType.primary,
    backgroundColor = null,
}) => {
    const [styleType, setStyleType] = useState(styles.primary)
    useEffect(()=>{
        if(variant !== ButtonVariant.outlined){

        
            if(type === ButtonType.primary){
                setStyleType(styles.primary)
            }
            if(type === ButtonType.secondary){
                setStyleType(styles.secondary)
            }
            if(type === ButtonType.custom){
                setStyleType(styles.custom)
            }
            if(type === ButtonType.yes){
                setStyleType(styles.yes)
            }
            if(type === ButtonType.no){
                setStyleType(styles.no)
            }
        }else{
            setStyleType(styles.outlined)
        }
    },[])
    return ( 
        <Button
        fullWidth={true}
        variant={variant}
        onClick={(e)=>onClick(e)}
        className={`${styles.button} ${styleType}`}
        sx={{
            padding:{xs:'10px', sm:`${scaleByRatio(10)}px`, xl:'10px'},
            fontSize: {xs:'18px', sm:`${scaleByRatio(18)}px`, xl:'18px'},
            lineHeight: {xs:'24px', sm:`${scaleByRatio(24)}px`, xl:'24px'},
        }}
        >
            {text}
        </Button>
        

     );
}