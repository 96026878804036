import { useMutation } from "react-query";
import API from "./request";
import { API_PATH } from "../utils/config";

export const useSignUp = () => {
  return useMutation((data) => API.post(API_PATH.POST_SIGNUP, data));
};

export const useTermsAndConditions = () => {
  return useMutation((data) =>API.get(API_PATH.GET_TERMS_AND_CONDITIONS, data));
};

export const usePrivacyPolicy = () => {
  return useMutation((data) => API.get(API_PATH.GET_PRIVACY_POLICY, data));
};

export const useFAQs = () => {
  return useMutation((data) => API.get(API_PATH.GET_FAQS, data));
};

export const useSendMsgFromContactUs = () => {
  return useMutation((data) => API.post(API_PATH.POST_MESSAGE_CONTACTUS, data));
};

export const useGetSubscriptionPlanDetails = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_SUBSCRIPTION_PLAN_DETAILS, data)
  );
};

export const useLogin = () => {
  return useMutation((data) => API.post(API_PATH.LOGIN, data));
};

export const usePostBuySubscription = () => {
  return useMutation((data) => API.post(API_PATH.BUY_SUBSCRIPTION, data));
};

export const useSocialLoginGoogle = ()=>{
  return useMutation((data) => API.post(API_PATH.SOCIAL_LOGIN_GOOGLE, data));
}

export const useSocialLoginFacebook = ()=>{
  return useMutation((data) => API.post(API_PATH.SOCIAL_LOGIN_FACEBOOK, data));
}
