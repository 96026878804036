import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./index.module.scss";

import { Link, useNavigate } from "react-router-dom";
import { routeUrls } from "../../utils/constants";
import Typography from "@mui/material/Typography";
import { scaleByRatio } from "../../utils/helper";
import AppBar from "@mui/material/AppBar";

const Footer = () => {
  const nav = useNavigate();
  return (
    <Box component="footer" className={styles.footer}>
      <Grid
        container
        fullWidth
        direction="row"
        xs={12}
        className={styles.footerContainer}
        sx={{
          //minHeight: `${scaleByRatio(81)}px`,
          textAlign: "center",
          paddingTop: {xs:`${scaleByRatio(27)}px`, xl:'27px'},
          paddingBottom: {xs:`${scaleByRatio(27)}px`, xl:'27px'},
        }}
      >
        <Grid
          item
          xs={3}
          alignContent={"center"}
          variant="body2"
          align="center"
        ></Grid>
        <Grid container xs={6}>
          <Grid item xs={5}>
            <Typography
              variant="body2"
              className={styles.footerText}
              sx={{
                fontSize: {xs:`${scaleByRatio(18)}px`, xl:`18px`},
                textAlign: "right",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                nav(routeUrls.termsAndConditions);
              }}
            >
              Terms and Conditions
            </Typography>
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Typography
              variant="body2"
              className={styles.footerText}
              sx={{
                fontSize: {xs:`${scaleByRatio(18)}px`, xl:'18px'},
                textAlign: "left",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                nav(routeUrls.privacyPolicy);
              }}
            >
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          alignContent={"center"}
          variant="body2"
          align="center"
        ></Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
