export const ASSETS = {
  AppLogo: "../assets/AppLogo.svg",
};

export const routeUrls = {
    landingPage: '/',
    signUpPage:'/signup',
    signUpComplete:'/signup-complete',
    termsAndConditions:'/terms-and-conditions',
    privacyPolicy:'/privacy-policy',
    signupSubscription:'/signup-subscription'
}

export const STRIPE_API_KEY = 'pk_test_51MlXrhBqf4qNuaUc8k9lFgcuaa1sUqhpTUDWtydgfsZjYcpubUv6PK3Kv4Bgdql1txPTa72LkLhPv0NqiNcmMkMn00w97iBbWo';
export const STRIPE_RETURN_URL = window.location.origin+routeUrls.signUpComplete

