import { Box, Grid, Typography, styled } from "@mui/material";
import SubscriberImg from "../../assets/cuate.svg";
import { ReactComponent as CheckSVG } from "../../assets/check.svg";
import DollarSVG from "../../assets/attach-money.svg";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  ButtonType,
  ButtonVariant,
  CustomButton,
} from "../../atoms/CustomButton";

import { useFormik, Field, FormikProvider } from "formik";
import FAQs from "./FAQs";
import Button from "../../Components/Button";
import ContactUs from "./ContactUs";
import { useGetSubscriptionPlanDetails } from "../../services/mutations";
import { toast } from "react-toastify";

// const StyledDollar = styled(DollarSVG)({
//   width: "2rem",
//   height: "2rem",
//   // paddingBottom: "0.5rem",
// });

const CheckImg = styled(CheckSVG)({
  width: "1.5rem",
  height: "1.5rem",
  // paddingBottom: "0.5rem",
});

const Subscription = ({ QNAs }) => {
  const subscriptionDetails = useGetSubscriptionPlanDetails();

  const [planDetail, setPlanDetail] = useState();
  useEffect(() => {
    subscriptionDetails.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log(response);
          const data = response?.data;
          setPlanDetail(data?.result[0]);
          console.log(planDetail);
        },
        onError: (err) => {
          toast.error(
            err?.response?.data?.error_message ||
              "Something went wrong. Please try again."
          );
        },
      }
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "75rem",
          backgroundColor: "#FFC200",
          borderRadius: "2.5rem 2.5rem 0 0",
          padding: "3rem 1.5rem",
          position: "relative",
        }}
      >
        <Typography
          sx={{ fontSize: "2rem", fontWeight: "700", color: "#111111" }}
        >
          OUR SUBSCRIPTION PLANS
        </Typography>
        <Typography
          sx={{ fontSize: "1.25rem", fontWeight: "400", color: "#444444" }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          quis eros posuere, fermentum odio quis, elementum odio. Duis sagittis
          viverra purus sed egestas.
        </Typography>
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "center",
            backgroundColor: "",
            margin: "2rem auto",
          }}
        >
          <Grid
            item
            p={2}
            sx={{
              width: "21.5625rem",
              height: "20.5rem",
              backgroundColor: "white",
              border: "4px",
              borderRadius: "1.25rem",
              boxShadow: "0px 8px 16px 0px #0000003D",
            }}
          >
            <Grid pb={1} container sx={{ borderBottom: "2px solid #888888" }}>
              <Grid item xs={5} sx={{ backgroundColor: "" }}>
                <img
                  src={SubscriberImg}
                  alt="App Icon"
                  style={{
                    height: "6.25rem",
                    width: "6.620625rem",
                  }}
                />
              </Grid>

              <Grid item xs={6} pl={1}>
                <Grid spacing={2} container sx={{ backgroundColor: "" }}>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#223C6F",
                        fontSize: "1.25rem",
                        fontWeight: 700,
                      }}
                    >
                      {planDetail?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      spacing={1}
                      container
                      alignItems="center"
                      sx={{ backgroundColor: "" }}
                    >
                      <Grid pb={1} item xs={1}>
                        <img
                          src={DollarSVG}
                          alt="App Icon"
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          textAlign="center"
                          pb={1}
                          pl={1}
                          sx={{
                            color: "#FFC200",
                            fontSize: "2rem",
                            fontWeight: 400,
                            lineHeight: "1rem",
                          }}
                        >
                          {planDetail?.price}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          pl={5}
                          style={{
                            color: "#888888",
                            fontSize: "0.875rem",
                            fontWeight: 300,
                          }}
                        >
                          &nbsp;/{planDetail?.interval}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#888888",
                        fontSize: "0.625rem",
                        fontWeight: 400,
                        lineHeight: "0.75rem",
                      }}
                    >
                      *medication not included
                    </Typography>
                  </Grid>
                </Grid>
                {/* <Grid item pt={1}></Grid> */}
              </Grid>
            </Grid>
            <Grid
              container
              pt={3}
              sx={{
                backgroundColor: "",
                width: "100%",
                color: "#888888",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              <Grid
                // container
                item
                sx={{
                  backgroundColor: "",
                  // alignItems: "center",
                  // width: "100%",
                }}
              >
                <Grid container sx={{ backgroundColor: "", paddingBottom:`10px` }}>
                  <Grid item>
                    <CheckImg />
                  </Grid>
                  <Grid item>
                    <Typography display="inline">
                      Product offering type 1
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <CheckImg />
                  </Grid>
                  <Grid item>
                    <Typography display="inline">
                      Product offering type 2
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <CheckImg />
                  </Grid>
                  <Grid item>
                    <Typography display="inline">
                      Product offering type 3
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <CheckImg />
                  </Grid>
                  <Grid item>
                    <Typography display="inline">
                      Product offering type 4
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            //    pl={3} pt={16}
            justifyContent="center"
            margin="auto 2rem"
            // pl={3}
            // pl={{ md: 3, xs: 1.5 }}
            // pt={{ md: 11, xs: 3 }}
            sx={{ backgroundColor: "" }}
            p={5}
          >
            <Button
              label="Pay Up Front"
              variant="contained"
              sx={{
                width: "21.5625rem",
                // margin: "2rem auto",
                height: "5rem",
                backgroundColor: "#053D70",
                borderRadius: "0.625rem",
                color: "white",
                fontSize: "1.25rem",
                fontWeight: "700",
                padding: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", top: { md: "57%", xs: "64%" } }}>
        <FAQs QNAs={QNAs} />
        <ContactUs />
      </Box>
    </>
  );
};

export default Subscription;
