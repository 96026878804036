import React, { useState, useEffect } from "react";
import PageLayout from "../../Layout/PageLayout";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTermsAndConditions } from "../../services/mutations";
import { routeUrls } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { scaleByRatio } from "../../utils/helper";
import Loader from "../../molecule/Loader";
import { toast } from "react-toastify";
import styles from "./index.module.scss";

const TermsAndConditions = () => {
  const nav = useNavigate();
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const termsAndConditions = useTermsAndConditions();
  useEffect(() => {
    setLoading(true);
    termsAndConditions.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log("termsAndConditions response:", response);
          let data = response?.data[0].body || "";
          setBody(data);
          setLoading(false);
        },
        onError: (response) => {
          setLoading(false);
          toast.error(
            response?.message || "Something went wrong. Please try again."
          );
        },
      }
    );
  }, []);
  return (
    <>
      <Loader open={loading} />
      <PageLayout>
        <Box
          component="main"
          className={"mainBody"}
          sx={{
            marginTop: {xs:'104px', sm:`${scaleByRatio(104)}px`, xl:'104px'},
            // border: '1px solid #FF0000',
            marginLeft: {xs:`40px`,sm:`${scaleByRatio(130)}px`,xl:'130px'},
            marginRight: {xs:`40px`,sm:`${scaleByRatio(130)}px`,xl:'130px'},
          }}
        >
          <Grid container fullWidth direction="row" className={styles.header}>
            <Grid container fullWidth direction="row" justifyContent={"left"}>
              <Grid item xs={12} pt={1}>
                <Typography
                  // mr={1}
                  justifyItems={"left"}
                  className={styles.breadCrumbPrevious}
                  onClick={() => {
                    nav(routeUrls.landingPage);
                  }}
                  sx={{
                    fontSize: {xs:'14px', sm:`${scaleByRatio(14)}px`, xl:'14px'},
                    lineHeight: {xs:'17px', sm:`${scaleByRatio(17)}px`, xl:'17px'},
                  }}
                >
                  Home &gt; &nbsp;
                </Typography>
                <Typography
                  justifyItems={"left"}
                  className={styles.breadCrumbCurrent}
                  sx={{
                    fontSize: {xs:'14px', sm:`${scaleByRatio(14)}px`, xl:'14px'},
                    lineHeight: {xs:'17px', sm:`${scaleByRatio(17)}px`, xl:'17px'},
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              fullWidth
              direction="row"
              justifyContent={"center"}
              display={"flex"}
            >
              <Grid item xs={12}>
                <Typography
                  justifyItems={"center"}
                  className={styles.heading}
                  sx={{
                    fontSize: {xs:'23px', sm:`${scaleByRatio(30)}px`,xl:'30px'},
                    lineHeight: {xs:'36px', sm:`${scaleByRatio(36)}px`,xl:'36px'},
                  }}
                >
                  Terms and Conditions
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={"center"}
                display={"flex"}
                alignContent={"baseline"}
              >
                <Typography
                  justifyItems={"center"}
                  display={"flex"}
                  className={styles.underline}
                  sx={{
                    width: {xs:'100px',sm:`${scaleByRatio(100)}px`,xl:'100px'},
                  }}
                ></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container fullWidth direction="row" className={styles.body} 
          style={{
            maxHeight:'65vh',
            overflow:'auto'
          }}
          >
            <div dangerouslySetInnerHTML={{ __html: body }} />
            
          </Grid>
        </Box>
      </PageLayout>
    </>
  );
};

export default TermsAndConditions;
