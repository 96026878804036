export const scaleByRatio = (unit) => {
  if (window.devicePixelRatio > 1) {
    let scalePercentage = 1 / window.devicePixelRatio;
    // if(scalePercentage === 0.5){
    //     scalePercentage=0.40;
    // }
    // scalePercentage = scalePercentage - (0.2*scalePercentage)

    let calculatedUnit = scalePercentage * unit;
    return calculatedUnit;
  } else {
    return unit;
  }
};
